<template>
<div class="grid-page-wrapper">
    <h1 class="display-4">Registries dashboard</h1>
    <hr>
    <ModalSelectDateRange 
        :idModal="modalClosedRegistriesId" 
        title="Select date range" 
        @submit="routeToClosedRegistriesDashboard"/>

    <div class="d-grid gap-2 col-6 mx-auto">
        <button class="btn btn-success"
                data-bs-toggle="modal" 
                :data-bs-target="'#' + modalClosedRegistriesId"
            ><i class="fa-solid fa-check"></i> Closed registries</button>
    </div>
</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'

import ModalSelectDateRange from "../../shared/components/ModalSelectDateRange"

export default {
    components: {
        ModalSelectDateRange
    },
    setup() {
        const router = useRouter();
        const modalClosedRegistriesId = "modal-closed-registries";

        return {
            modalClosedRegistriesId,

            routeToClosedRegistriesDashboard: ($event, formData) => {
                console.log("Creating product");
                console.log($event);
                console.log(formData);
                router.push({
                    name: 'backoffice-dashboard-registries-closed',
                    params: {
                        start_date: formData.start_date,
                        end_date: formData.end_date
                    },
                })

                let modal = Modal.getInstance(document.getElementById(modalClosedRegistriesId))
                modal.hide()
            }
        }
    }
}
</script>

<style>

</style>