<template>
<div class="modal fade" :id="idModal" :ref="idModal" tabindex="-1" :aria-labelledby="idModal" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ title }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form>
                <div class="mb-3">
                    <label for="input-start-time" class="form-label">Start time</label>
                    <Datepicker 
                        uid="start-time"
                        inputClassName="form-control"
                        v-model="start_date" aria-describedby="starttimeHelp" 
                        :enable-time-picker="false" />
                    <div v-if="valErrors.start_date === ''" id="starttimeHelp" class="form-text">Set initial date.</div>
                    <div v-else class="form-text text-danger">{{ valErrors.start_date }}</div>
                </div>
                <div class="mb-3">
                    <label for="input-reference" class="form-label">End time</label>
                    <Datepicker 
                        uid="end-time"
                        inputClassName="form-control"
                        v-model="end_date" aria-describedby="endtimeHelp" 
                        :enable-time-picker="false" />
                    <div v-if="valErrors.end_date === ''" id="endtimeHelp" class="form-text">Set end date.</div>
                    <div v-else class="form-text text-danger">{{ valErrors.end_date }}</div>
                </div>
            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" @click="submit">{{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';

import { ref, reactive, watch, onMounted } from 'vue';


export default {
    components: {
        Datepicker
    },
    props: {
        idModal: {
            required: true,
            type: String
        },
        submitButtonText: {
            type: String,
            default: "OK"
        },
        title: {
            type: String,
            default: "Select a Date range"
        },
        formData: {
            type: Object
        },
        validations: {
            type: Object
        }
    },
    
    emits: ['submit'],

    setup(props, context) {
        const start_date = ref(null)
        const end_date = ref(null)

        const valErrors = reactive({ start_date: '', end_date: '' })

        const initComponent = async() => {

        }

        const submit = ($event) => {
            cleanValidations()
            const formData = { 
                start_date: start_date.value,
                end_date: end_date.value
            }
            console.log(formData);
            context.emit('submit', $event, formData)
        }

        const cleanForm = () => {
            cleanValidations()
            start_date.value = null
            end_date.value = null
        }

        const cleanValidations = () => {
            for (const field in valErrors) {
                valErrors[field] = ''
            }
        }

        watch(() => props.formData, (newFormData) => {
            console.log(newFormData);
            if (newFormData) {
                start_date.value = newFormData.start_date
                end_date.value = newFormData.end_date
            }
        })

        watch(() => props.validations, (newValidations) => {
            cleanValidations()
            for (const field in newValidations) {
                console.log(`Error in field ${field}: ${newValidations[field]}`);
                valErrors[field] = newValidations[field][0]
            }
        })

        watch(() => start_date, (newValue) => {
            console.log(newValue);
            let start_date = new Date(newValue).setHours(0,0,0,0)
            console.log(start_date);
            if (!end_date.value) {
                end_date.value = newValue
            }
        })

        watch(() => valErrors.start_date, (newValue) => {
            console.log(newValue);
            let input = document.getElementById('dp-input-start-time')
            if (newValue !== '') {
                input.classList.remove("is-valid");
                input.classList.add('is-invalid')
            } else {
                input.classList.add('is-valid')
                input.classList.remove("is-invalid");
            }
        })

        watch(() => valErrors.end_date, (newValue) => {
            console.log(newValue);
            let input = document.getElementById('dp-input-end-time')
            if (newValue !== '') {
                input.classList.remove("is-valid");
                input.classList.add('is-invalid')
            } else {
                input.classList.add('is-valid')
                input.classList.remove("is-invalid");
            }
        })

        initComponent()

        onMounted(() => {
            let $modal = document.getElementById(props.idModal)
            $modal.addEventListener('hidden.bs.modal', function () {
                cleanForm()
            })
        })


        return {
            start_date,
            end_date,

            valErrors,

            submit,
            cleanForm
        }
    }

}
</script>

<style>

</style>